






import { Component, Vue } from "vue-property-decorator";
import AdminUserPermissionGroupTable from "@/components/admin/user/permission/group/AdminUserPermissionGroupTable.vue";

/**
 * AdminUserPermissionGroupManagement view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminUserPermissionGroupTable,
    },
})
export default class AdminUserPermissionGroupManagement extends Vue {}
