import { render, staticRenderFns } from "./AdminUserPermissionGroupTable.vue?vue&type=template&id=374737ee&"
import script from "./AdminUserPermissionGroupTable.vue?vue&type=script&lang=ts&"
export * from "./AdminUserPermissionGroupTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VTextField})
