


































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { Permission, PermissionGroup } from "@/types/user/permission";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";

/**
 * AdminUserPermissionGroupTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
    },
})
export default class AdminUserPermissionGroupTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;

    private permissionGroups: PermissionGroup[] = [];
    private permissions: Permission[] = [];

    private defaultPermissionGroupItem: PermissionGroup = {
        id: -1,
        name: "",
        permissions: [],
    };

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$tc("language.name").toString(),
                value: "name",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            [this.permissionGroups, this.permissions] = await Promise.all([
                this.fetchPermissionGroups(),
                this.fetchPermissions(),
            ]);
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all permission groups
     *
     * @returns Promise<PermissionGroup[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchPermissionGroups(): Promise<PermissionGroup[]> {
        const response = (
            await axios.get<APIResponse<PermissionGroup[]>>(
                "/admin/permissions/groups"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches all permissions
     *
     * @returns Promise<Permission[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchPermissions(): Promise<Permission[]> {
        const response = (
            await axios.get<APIResponse<Permission[]>>("/admin/permissions")
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
